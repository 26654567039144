import './App.css';
import EvolvedApp from './components/EvolvedApp';
import { useFrontContext } from './providers/frontContext';
import ErrorBoundary from './components/ErrorBoundary';


function App() {
  const context = useFrontContext();

  if (!context)
    return (
      <ErrorBoundary>
      <div className="App">
        <p>Waiting to connect to the Front context.</p>
      </div>
      </ErrorBoundary>
    )

  switch(context.type) {
    case 'noConversation':
      return (
        <div className="App">
          <p>No conversation selected.</p>
        </div>
      );
    case 'singleConversation':
      return (
        <div className="App">
          <EvolvedApp />
        </div>
      );
    case 'multiConversations':
      return (
        <div className="App">
          <p>Select only one conversation to use this app.</p>
        </div>
      );
    default:
      console.error(`Unsupported context type: ${context.type}`);
      break;
  };
}

export default App;

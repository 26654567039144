import React, {useEffect, useState} from 'react';
import { useFrontContext } from '../providers/frontContext';
import Front from '@frontapp/plugin-sdk';
import Retool from "react-retool";
import ErrorBoundary from './ErrorBoundary';

function EvolvedApp() {
  document.body.style.overflow = "hidden";
  const context = useFrontContext();
  const [latestMessageFrom, setLatestMessageFrom] = useState();
  const [latestMessageTo, setLatestMessageTo] = useState();
  //Params
  const frontUser = (context.teammate && context.teammate.id) ? context.teammate.id : null;
  const conv = (context.conversation && context.conversation.id) ? context.conversation.id : null;
  const convLinks = JSON.stringify((context.conversation && context.conversation.links) ? context.conversation.links : null);
  const opsInbox = "inb_9ig0c";

useEffect(() => {
context.listMessages()
  .then(response => {
    if (response.results.length > 0) {
      if (response.results.length == 1) {
        setLatestMessageFrom([].concat(response.results[0].from))
      } else {
        setLatestMessageFrom([].concat(response.results[0].from,response.results[1].from))
      }
    } else {
      setLatestMessageFrom(undefined);
    }
  }).catch((error) => {
        // Handle the error here, e.g., log the error or display an error message
        console.error("Error fetching latest messages from context:", error);
        window.location.reload();
        return Promise.resolve();
      });
}, [context]);

useEffect(() => {
context.listMessages()
  .then(response => {
    if (response.results.length > 0) {
      if (response.results.length == 1) {
        setLatestMessageTo([].concat(response.results[0].to))
      } else {
        setLatestMessageTo([].concat(response.results[0].to,response.results[1].to))
      }
    } else {
      setLatestMessageFrom(undefined);
    }
  }).catch((error) => {
        // Handle the error here, e.g., log the error or display an error message
        console.error("Error fetching latest messages from context:", error);
        window.location.reload();
        return Promise.resolve();
      });
}, [context]);

  const retoolConvPrep = { conv: conv, user: frontUser, people: [].concat(latestMessageFrom,latestMessageTo), links: convLinks}
  const retoolConv = {convData: retoolConvPrep}
  /*useEffect(() => {
    const button = document.getElementById('move-btn');
    if (button) {
      button.addEventListener('click', handleClick);
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const handleClick = () => {
    Front.move(opsInbox);
    Front.assign(user);
  };

  window.addEventListener(
  "message",
  function (e) {
    console.log("Parent window: ", e.data);
  },
  false
);*/

  return (
    <ErrorBoundary>
    <div className="App">
      {/*<button id="move-btn">Share this conversation in BP Coord</button>*/}
      <div className="retool-box" style={{ height: '100vh' }}>
            <Retool url="https://evolved.retool.com/embedded/public/1f3bd3c1-d6fd-41ca-88cf-dca256356fbe" data={retoolConv}/>
      </div>
    </div>
    </ErrorBoundary>
  );
}

export default EvolvedApp;
